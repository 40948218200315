import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class About extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true,
          handler: this._handleBack
        },
        { 
          label: 't(Next)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(About Page)',
              type: 'segment',
              fields: [
                { 
                  label: 't(Company / Personal Bio)', 
                  placeholder: 't(Enter a 2-4 paragraph bio)', 
                  name: 'website_about_bio', 
                  type: 'textarea', 
                  defaultValue: config.website_about_bio 
                },
                { 
                  label: 't(Additional Photos)', 
                  name: 'website_about_photo_ids', 
                  type: 'attachmentfield', 
                  allow: { content_types: ['images'] }, 
                  prompt: 't(Upload Photo(s))', 
                  multiple: true, 
                  defaultValue: config.website_about_photo_ids 
                },
                { 
                  label: 't(Team)',
                  name: 'website_about_team',
                  instructions: 't(If you want a team section on your about page, please add your team members below.)',
                  type: 'collectionfield',
                  fields: [
                    { 
                      type: 'fields',
                      fields: [
                        { 
                          label: 't(First Name)',
                          name: 'first_name',
                          type: 'textfield' 
                        },
                        { 
                          label: 't(Last Name)',
                          name: 'last_name',
                          type: 'textfield' 
                        }
                      ] 
                    },
                    { 
                      label: 't(Job Title)',
                      name: 'job_title',
                      type: 'textfield' 
                    },
                    { 
                      label: 't(Bio)',
                      name: 'bio',
                      type: 'textarea' 
                    },
                    { 
                      label: 't(Email)',
                      name: 'email',
                      type: 'emailfield' 
                    },
                    { 
                      label: 't(Phone)',
                      name: 'phone',
                      type: 'phonefield' 
                    },
                    { 
                      label: 't(Social)',
                      name: 'facebook',
                      type: 'urlfield',
                      service: 'facebook',
                      placeholder: 't(Enter Facebook URL)' 
                    },
                    { 
                      name: 'instagram',
                      type: 'urlfield',
                      service: 'instagram',
                      placeholder: 't(Enter Instagram URL)' 
                    },
                    { 
                      name: 'linkedin',
                      type: 'urlfield',
                      service: 'linkedin',
                      placeholder: 't(Enter LinkedIn URL)' 
                    },
                    { 
                      label: 't(Photo)',
                      name: 'photo_id',
                      type: 'attachmentfield',
                      allow: { content_types: ['images'] },
                      multiple: false 
                    }
                  ],
                  entity: 'member',
                  orderable: false,
                  cloneable: false,
                  token: ({ value }) => (
                    <div className="token">
                      { value.first_name } { value.last_name }
                    </div>
                  ),
                  defaultValue: config.website_about_team || [] 
                }
              ]
            }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata } = this.props
    this.props.onNext({
      ...formdata,
      ...config
    })
  }

}

export default About
