import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Pages extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true,
          handler: this._handleBack
        },
        { 
          label: 't(Next)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            {
              label: 't(Additonal Pages)',
              instructions: 't(Provide content for your page)',
              type: 'segment',
              fields: [
                { 
                  label: 't(Service and Fees Doc)',
                  instructions: 't(Please upload a word doc outlining your services and fees)',
                  name: 'website_service_fees_id',
                  type: 'attachmentfield',
                  prompt: 't(Upload Document)',
                  multiple: false,
                  defaultValue: config.website_service_fees_id
                },
                { 
                  label: 't(Terms and Conditions Doc)',
                  instructions: 't(Please upload a word doc outlining your terms and conditions)',
                  name: 'website_terms_conditions_id',
                  type: 'attachmentfield',
                  prompt: 't(Upload Document)',
                  multiple: false,
                  defaultValue: config.website_terms_conditions_id
                },
                { 
                  label: 't(Consultation Form)',
                  instructions: 't(Please add a doc of the questions you would like on your consultation form)',
                  name: 'website_consultation_id',
                  type: 'attachmentfield',
                  prompt: 't(Upload Document)',
                  multiple: false,
                  defaultValue: config.website_consultation_id
                },
                { 
                  label: 't(Consultation Embed Code)',
                  instructions: 't(Please paste in the html embed code to your third-party consultation form)',
                  name: 'website_consultation_embed',
                  type: 'textarea',
                  defaultValue: config.website_consultation_embed
                },
                { 
                  label: 't(Why Use a Travel Advisor Page)',
                  instructions: 't(Do you want to a Why Use a Travel Advisor Page on your website?)',
                  name: 'website_why_travel_advisor',
                  type: 'radiogroup', options: [
                    { value: 'yes', text: 't(Yes)' },
                    { value: 'no', text: 't(No)' }
                  ],
                  defaultValue: config.website_why_travel_advisor || 'yes' 
                }
              ]
            }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata } = this.props
    this.props.onNext({
      ...formdata,
      ...config
    })
  }

}

export default Pages
