import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Visual extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true,
          handler: this._handleBack
        },
        { 
          label: 't(Next)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Visual Style)',
              type: 'segment',
              fields: [
                { 
                  label: 't(Primary Color)',
                  name: 'website_primary_color',
                  type: 'textfield',
                  defaultValue: config.website_primary_color 
                },
                { 
                  label: 't(Secondary Color)',
                  name: 'website_secondary_color',
                  type: 'textfield',
                  defaultValue: config.website_secondary_color 
                },
                { 
                  label: 't(Photo Choices)',
                  name: 'website_photo_choices',
                  type: 'textfield',
                  instructions: 't(Please list your image choices to be used throughout the website. The more specific you are the better! A few examples are: Italy, London, Mountain, Lake, Desert, City, Urban, Greece, Caribbean, New York, Safari, Cruise, Resort, Hotels, Yachts, Ski, Snorkel, Bike, Drink, Food, Nightlife etc.)',
                  defaultValue: config.website_photo_choices 
                },
                { 
                  label: 't(Photos)',
                  instructions: 't(Add your own personal, royalty-free, or approved supplier imagery to be used throughout your website. High resolution and professional quality images recommended.)',
                  name: 'website_photo_ids',
                  type: 'attachmentfield',
                  allow: { content_types: ['images'] },
                  multiple: true,
                  defaultValue: config.website_photo_ids 
                }  
              ] 
            }
          ] 
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata } = this.props
    this.props.onNext({
      ...formdata,
      ...config
    })
  }

}

export default Visual
