import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Contact extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true,
          handler: this._handleBack
        },
        { 
          label: 't(Next)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            {
              label: 't(Contact / Scheduling)',
              type: 'segment',
              fields: [
                { 
                  name: 'website_contact_strategy',
                  type: 'radiogroup',
                  options: [
                    { text: 't(Use a website contact form)', value: 'website' },
                    { text: 't(Use a Calendly scheduling form)', value: 'calendly' }
                  ],
                  defaultValue: config.website_contact_strategy || 'website' 
                },
                { 
                  label: 't(Calendly / Scheduling Link)',
                  name: 'website_calendly_url',
                  type: 'urlfield',
                  show: config.website_contact_strategy === 'calendly',
                  defaultValue: config.website_calendly_url 
                },
                { 
                  label: 't(Calendly Inline Embed HTML Code)',
                  name: 'website_calendly_embed',
                  type: 'textarea',
                  show: config.website_contact_strategy === 'calendly',
                  defaultValue: config.website_calendly_embed 
                }
              ]
            }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata } = this.props
    this.props.onNext({
      ...formdata,
      ...config
    })
  }

}

export default Contact
