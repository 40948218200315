import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import TextStyleField from '@admin/components/mjson_designer/components/textstylefield'
import MenuField from '@admin/components/mjson_designer/components/menufield'
import { normalizeConfig } from '../../../utils'
import Form from '@admin/components/form'
import * as styles from '../styles'
import PropTypes from 'prop-types'
import settings from '../settings'
import React from 'react'

export const Text = (props) => <ResponsiveField { ...props } component={ TextStyleField } />

class EditMenuBlock extends React.PureComponent {

  static propTypes = {
    device: PropTypes.string,
    entity: PropTypes.object,
    theme: PropTypes.object,
    type: PropTypes.string,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    const { entity } = props
    this.state = {
      default: entity,
      config: entity
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getForm() {
    const { device, entity, theme, type, website } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      onSuccess: this._handleDone,
      showHeader: false,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      tabs: [
        {
          label: 't(Content)',
          sections: [
            {
              fields: [
                { label: 't(Menu)', name: 'content.menu', type: MenuField, website, value: config.content.menu },
                { label: 't(Style)', type: 'segment', fields: [
                  { name: 'content.display', type: 'dropdown', options: [
                    { value: 'horizontal', text: 't(Horizonal)' },
                    { value: 'vertical', text: 't(Vertical)' },
                    { value: 'slidein', text: 't(Slide In)' }
                  ], defaultValue: 'horizontal', value: config.content.display },
                  ...config.content.layout === 'horizontal' ? [
                    { label: 'Separator', name: 'content.separator', type: 'textfield', maxLength: 1, defaultValue: '|', value: config.content.separator }
                  ] : [],
                  ...config.content.layout === 'vertical' ? [
                    { name: 'content.show_title', type: 'checkbox', defaultValue: false, value: config.content.show_title, prompt: 'Show menu title' }
                  ] : []
                ] }
              ]
            }
          ]
        },
        {
          label: 't(Styles)',
          sections: [
            {
              label: 't(Icon)',
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 't(Padding)', name: 'styles.iconPadding', type: styles.Spacing, device, value: config.styles.iconPadding },
                { label: 't(Background)', name: 'styles.iconBackground', type: styles.Background, theme, device, value: config.styles.iconBackground },
                { label: 't(Color)', name: 'styles.iconColor', type: styles.Color, theme, device, value: config.styles.iconColor },
                { label: 't(Filter)', name: 'styles.iconFilters', type: styles.Filters, theme, device, value: config.styles.iconFilters }
              ]
            },
            {
              label: 'Panel',
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 'Position', name: 'styles.panelPosition', type: 'dropdown', options: [
                  { value: 'left', text: 't(Left)' },
                  { value: 'right', text: 't(Right)' }
                ], value: config.styles.panelPosition, defaultValue: 'left' },
                { label: 't(Background)', name: 'styles.panelBackground', type: styles.Background, theme, device, value: config.styles.panelBackground }
              ]
            },
            {
              label: 't(Title)',
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 't(Text)', name: 'styles.title_text', type: styles.Text, theme, canHover: false, canResponsive: false, marginLeft: false, value: config.styles.title_text },
                { label: 't(Border)', name: 'styles.title_border', device, theme, type: styles.Border, value: config.styles.title_border }
              ]
            },
            {
              label: 't(Menu)',
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 'Links', name: 'styles.linkText', type: Text, canResponsive: false, canHover: true, device, theme, prefix: 'a', value: config.styles.linkText },
                { label: 'Active Link', name: 'styles.activeText', type: Text, canResponsive: false, canHover: true, device, theme, prefix: 'a', value: config.styles.activeText },
                { label: 'Link Padding', name: 'styles.linkPadding', type: styles.Spacing, value: config.styles.linkPadding },
                { label: 'Separator Color', name: 'styles.separatorColor', type: 'colorfield', theme, value: config.styles.separatorColor },
                { label: 'Horizonal Overflow', name: 'styles.horizontalOverflow', canResponsive: false, device, type: 'dropdown', options: [
                  { value: 'default', text: 't(Default)' },
                  { value: 'visible', text: 't(Visible)' },
                  { value: 'scroll', text: 't(Scroll)' },
                  { value: 'hidden', text: 't(Hidden)' },
                  { value: 'auto', text: 't(Auto)' }
                ], defaultValue: 'default', value: config.styles.horizontalOverflow }
              ]
            },
            styles.BackgroundSection(config, device, theme, website, type),
            styles.BoxShadowSection(config, device, theme, website, type),
            styles.BorderSection(config, device, theme, website, type),
            styles.SizingSection(config, device, theme, website, type, true, true),
            styles.SpacingSection(config, device, theme, website, type),
            styles.TransformSection(config, device, theme, website, type),
            styles.AnimationSection(config, device, theme, website, type),
            styles.FiltersSection(config, device, theme, website, type),
            styles.OverflowSection(config, device, theme, website, type),
            styles.VisibilitySection(config, device, theme, website, type)
          ]
        },
        {
          label: 't(Settings)',
          sections: settings(config, type, entity)
        }
      ]
    }
  }

  _getNormalized() {
    const { entity } = this.props
    const { config } = this.state
    return normalizeConfig({
      ...entity,
      meta: config.meta,
      settings: config.settings,
      styles: config.styles,
      content: config.content
    })
  }

  _handleCancel() {
    this.props.onDone(this.state.default)
  }

  _handleChange() {
    const config = this._getNormalized()
    this.props.onChange(config)
  }

  _handleDone() {
    const config = this._getNormalized()
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

}

export default EditMenuBlock
