import CaptionStyleField from '@admin/components/mjson_designer/components/captionstylefield'
import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import ImageStyleField from '@admin/components/mjson_designer/components/imagestylefield'
import DisplayField from '@admin/components/mjson_designer/components/displayfield'
import MediaField from '@admin/components/form/mediafield'
import LinkField from '@admin/components/mjson_designer/components/linkfield'
import { normalizeConfig } from '../../../utils'
import Form from '@admin/components/form'
import * as styles from '../styles'
import PropTypes from 'prop-types'
import settings from '../settings'
import React from 'react'

const Display = (props) => <ResponsiveField { ...props } component={ DisplayField } />
const Media = (props) => <ResponsiveField { ...props } component={ MediaField } />

class EditImageBlock extends React.PureComponent {

  static propTypes = {
    device: PropTypes.string,
    entity: PropTypes.object,
    theme: PropTypes.object,
    type: PropTypes.string,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    const { entity } = props
    this.state = {
      default: entity,
      config: entity
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getForm() {
    const { device, entity, theme, type, website } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      onSuccess: this._handleDone,
      showHeader: false,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      tabs: [
        {
          label: 't(Content)',
          sections: [
            {
              fields: [
                { label: 't(Image)', name: 'content.image', type: Media, device, website, value: config.content.image },
                { label: 't(Alt)', name: 'content.alt', type: 'textfield', required: config.content.image, value: config.content.alt },
                { label: 't(Link To)', name: 'content.link', type: LinkField, website, value: config.content.link }
              ]
            }
          ]
        },
        {
          label: 't(Styles)',
          sections: [
            {
              label: 't(Image)',
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 't(Display)', name: 'styles.display', device, canHover: false, type: Display, omitFeatures: ['height'], theme, defaultValue: {}, value: config.styles.display },
                { label: 't(Image)', name: 'styles.image', device, type: ImageStyleField, theme, defaultValue: {}, value: config.styles.image },
                { label: 't(Caption)', name: 'styles.caption', device, type: CaptionStyleField, theme, defaultValue: {
                }, value: config.styles.caption }
              ]
            },
            ...config.styles.hasCaption ? [
              {
                label: 't(Caption)',
                collapsing: true,
                collapsed: true,
                fields: [
                  { label: 't(Padding)', name: 'styles.captionPadding', type: styles.Spacing, value: config.styles.captionPadding },
                  ...styles.TextFields(config, device, theme, website, 'caption')
                ]
              }
            ] : [],
            styles.BackgroundSection(config, device, theme, website, type),
            styles.BoxShadowSection(config, device, theme, website, type),
            styles.BorderSection(config, device, theme, website, type),
            styles.SizingSection(config, device, theme, website, type, true, true),
            styles.SpacingSection(config, device, theme, website, type),
            styles.TransformSection(config, device, theme, website, type),
            styles.AnimationSection(config, device, theme, website, type),
            styles.FiltersSection(config, device, theme, website, type),
            styles.OverflowSection(config, device, theme, website, type),
            styles.VisibilitySection(config, device, theme, website, type)
          ]
        },
        {
          label: 't(Settings)',
          sections: settings(config, type, entity)
        }
      ]
    }
  }

  _getNormalized() {
    const { entity } = this.props
    const { config } = this.state
    return normalizeConfig({
      ...entity,
      meta: config.meta,
      settings: config.settings,
      styles: config.styles,
      content: config.content
    })
  }

  _handleCancel() {
    this.props.onDone(this.state.default)
  }

  _handleChange() {
    const config = this._getNormalized()
    this.props.onChange(config)
  }

  _handleDone() {
    const config = this._getNormalized()
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

}

export default EditImageBlock
