import { AppWindow } from 'lucide-react'
import { BadgePlus } from 'lucide-react'
import { Calendar, ChartNetwork, Cog } from 'lucide-react'
import { MonitorPlay, MonitorSmartphone } from 'lucide-react'
import { Newspaper } from 'lucide-react'
import { PencilRuler, Presentation } from 'lucide-react'
import { ScanFace, Shapes, SquareMenu } from 'lucide-react'

export default {
  app_window: AppWindow,
  badge_plus: BadgePlus,
  calendar: Calendar,
  chart_network: ChartNetwork,
  cog: Cog,
  monitor_play: MonitorPlay,
  monitor_smartphone: MonitorSmartphone,
  newspaper: Newspaper,
  pencil_ruler: PencilRuler,
  presentation: Presentation,
  scan_face: ScanFace,
  shapes: Shapes,
  square_menu: SquareMenu
}