import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const strategies = {
  url: {
    title: 't(I Already Have a Website)',
    text: 't(Let us know your url, so we can link to it from your content)'
  },
  design: {
    title: 't(Design a New Website for Me)',
    text: 't(Hire Truevail to design a new website for you. Our support team will reach out to you with additional details and purchase options)'
  },
  optout: {
    title: 't(I Dont Need a Website)',
    text: 't(If you dont want have or neeed a website, we can skip this step. Select this option if you don\'t need a website)'
  }
}

const WebsiteStrategyToken = ({ value }) => (
  <div className="token">
    <strong><T text={ strategies[value].title } /></strong><br />
    <T text={ strategies[value].text } />
  </div>
)

WebsiteStrategyToken.propTypes = {
  value: PropTypes.string
}

export default WebsiteStrategyToken
