import AdvisorSocialCampaignToken from '@apps/truevail/admin/tokens/advisor_social_campaign'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Social Publishings)',
  collection: {
    endpoint: '/api/admin/truevail/admin/social_publishings',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Publishing)', key: 'advisor.title', sort: 'advisor', primary: true, format: (social_campaign) => (
        <AdvisorSocialCampaignToken social_campaign={ social_campaign } />
      ) }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Date Range)', key: 'post_at', type: 'daterange', include: ['day','this','last','next'] },
            { name: 't(Type)', key: 'type', type: 'select', options: ['photo','video','link','story','reel'] },
            { name: 't(Status)', key: 'status', type: 'select', multiple: true, options: ['scheduled','posting','posted','failed'], format: StatusToken }
          ]
        }
      ],
      system: [
        { id: 0, title: 't(Posting Today)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'post_at', operator: '$dr', parent: null, value: 'today' }
          ]
        } },
        { id: 1, title: 't(Posted Yesterday)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'post_at', operator: '$dr', parent: null, value: 'yesterday' }
          ]
        } },
        { id: 2, title: 't(Posted)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'status', operator: '$eq', parent: null, value: 'posted' }
          ]
        } },
        { id: 3, title: 't(Failed)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'status', operator: '$eq', parent: null, value: 'failed' }
          ]
        } }
      ]
    },
    defaultSort: { key: 'post_at', order: 'desc' },
    empty: {
      icon: 'comments',
      title: 't(No Publishings)',
      text: 't(There are no published social campaigns)'
    },
    entity: 'publishing',
    onClick: (record) => context.router.push(`/admin/truevail/admin/social_publishings/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
