import TextOverrideStyleField from '@admin/components/mjson_designer/components/textstylefield'
import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import IconStyleField from '@admin/components/mjson_designer/components/iconstylefield'
import FollowField from '@admin/components/mjson_designer/components/followfield'
import AlignmentField from '@admin/components/form/alignmentfield'
import { normalizeConfig } from '../../../utils'
import Form from '@admin/components/form'
import * as styles from '../styles'
import PropTypes from 'prop-types'
import settings from '../settings'
import React from 'react'

const TextOverride = (props) => <ResponsiveField { ...props } component={ TextOverrideStyleField } />
const Alignment = (props) => <ResponsiveField { ...props } component={ AlignmentField } />

class EditSocialBlock extends React.PureComponent {

  static propTypes = {
    device: PropTypes.string,
    entity: PropTypes.object,
    theme: PropTypes.object,
    type: PropTypes.string,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    const { entity } = props
    this.state = {
      default: entity,
      config: entity
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getForm() {
    const { device, entity, theme, type, website } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      onSuccess: this._handleDone,
      showHeader: false,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      tabs: [
        {
          label: 't(Content)',
          sections: [
            {
              fields: [
                { label: 't(Title)', name: 'content.title', type: 'textfield', defaultValue: 'Follow Us:', value: config.content.title },
                { label: 't(Social Links)', name: 'content.social', type: FollowField, defaultValue: {
                  preset: 'default'
                }, value: config.content.social }
              ]
            },
            {
              label: 't(Options)',
              fields: [
                { label: 'Direction', name: 'styles.direction', type: 'dropdown', options: [
                  { value: 'horizontal', text: 't(Horizontal)' },
                  { value: 'vertical', text: 't(Vertical)' }
                ], defaultValue: 'horizontal', value: config.styles.direction },
                { label: 't(Show)', name: 'content.show', type: 'checkboxgroup', options: [
                  { value: 'title', text: 'Title' }
                ], defaultValue: ['title'], value: config.content.show }
              ]
            }
          ]
        },
        {
          label: 't(Styles)',
          sections: [
            {
              label: 't(Social)',
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 't(Icons)', name: 'styles.icon', collapsing: true, collapsed: true, type: IconStyleField, device, theme, defaultValue: {
                  size: '32px',
                  radius: {
                    type: 'circular'
                  },
                  backgroundColor: {
                    value: 'default'
                  },
                  color: {
                    value: 'default'
                  }
                }, value: config.styles.icon },
                { label: 't(Title Text)', name: 'styles.titleText', collapsing: true, collapsed: true, type: TextOverride, device, theme, defaultValue: {
                  preset: 'heading',
                  color: {
                    value: 'default'
                  }
                }, value: config.styles.titleText },
                { label: 't(Alignment)', name: 'styles.align', collapsing: true, collapsed: true, type: Alignment, defaultValue: 'left', value: config.styles.align }

              ]
            },
            styles.BackgroundSection(config, device, theme, website, type),
            styles.BoxShadowSection(config, device, theme, website, type),
            styles.BorderSection(config, device, theme, website, type),
            styles.SizingSection(config, device, theme, website, type, true, true),
            styles.SpacingSection(config, device, theme, website, type),
            styles.TransformSection(config, device, theme, website, type),
            styles.AnimationSection(config, device, theme, website, type),
            styles.FiltersSection(config, device, theme, website, type),
            styles.OverflowSection(config, device, theme, website, type),
            styles.VisibilitySection(config, device, theme, website, type)
          ]
        },
        {
          label: 't(Settings)',
          sections: settings(config, type, entity)
        }
      ]
    }
  }

  _getNormalized() {
    const { entity } = this.props
    const { config } = this.state
    return normalizeConfig({
      ...entity,
      meta: config.meta,
      settings: config.settings,
      styles: config.styles,
      content: config.content
    })
  }

  _handleCancel() {
    this.props.onDone(this.state.default)
  }

  _handleChange() {
    const config = this._getNormalized()
    this.props.onChange(config)
  }

  _handleDone() {
    const config = this._getNormalized()
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }
}

export default EditSocialBlock
