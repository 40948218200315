import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Booking extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true,
          handler: this._handleBack
        },
        { 
          label: 't(Next)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            {
              label: 't(Booking Tools)',
              type: 'segment',
              fields: [
                { 
                  name: 'website_booking_tools',
                  type: 'collectionfield',
                  fields: [
                    { 
                      label: 't(Name)',
                      name: 'name',
                      type: 'textfield' 
                    },
                    { 
                      label: 't(Description)',
                      name: 'description',
                      type: 'textarea' 
                    },
                    { 
                      label: 't(URL)',
                      name: 'url',
                      type: 'urlfield' 
                    }
                  ],
                  entity: 'tool',
                  orderable: false,
                  cloneable: false,
                  token: ({ value }) => (
                    <div className="token">
                      { value.name }
                    </div>
                  ),
                  defaultValue: config.website_booking_tools || []
                }
              ]
            }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata } = this.props
    this.props.onNext({
      ...formdata,
      ...config
    })
  }

}

export default Booking
