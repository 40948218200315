import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const types = {
  singlepage: {
    title: 't(Single Page Website)',
    text: 't(One long single page website with all your content and services)',
    charges: '$199 one-time setup fee'
  },
  fivepage: {
    title: 't(5-Page Website)',
    text: 't(A 5-page website with a homepage, about page, services page, contact page and blog)',
    charges: '$499 one-time setup fee'
  }
}

const WebsiteTypeToken = ({ value }) => (
  <div className="token">
    <strong><T text={ types[value].title } /></strong> (<span className="alert">{ types[value].charges }</span>)<br />
    <T text={ types[value].text } />
  </div>
)

WebsiteTypeToken.propTypes = {
  value: PropTypes.string
}

export default WebsiteTypeToken
