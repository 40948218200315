import DomainSegments from './domain_segments'
import Page from '@admin/components/page'
import Subdomains from './subdomains'
import Overview from './overview'
import Queued from './queued'
import React from 'react'
import Sent from './sent'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.domain.name,
  manager: {
    path: `/admin/truevail/admin/domains/${resources.domain.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview domain={ resources.domain } />
      },
      {
        label: 't(Segments)',
        path: '/segments',
        panel: <DomainSegments domain={ resources.domain } />
      },
      {
        label: 't(Subdomains)',
        path: '/subdomains',
        show: resources.domain.tld_id === null,
        panel: <Subdomains domain={ resources.domain } />
      },
      {
        label: 't(Queued)',
        path: '/queued',
        panel: <Queued domain={ resources.domain } />
      },
      {
        label: 't(Sent)',
        path: '/sent',
        panel: <Sent domain={ resources.domain } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  domain: `/api/admin/truevail/admin/domains/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
