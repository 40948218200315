import ButtonStyleField from '@admin/components/mjson_designer/components/buttonstylefield'
import FormDisplayField from '@admin/components/mjson_designer/components/formdisplayfield'
import LabelStyleField from '@admin/components/mjson_designer/components/labelstylefield'
import InputStyleField from '@admin/components/mjson_designer/components/inputstylefield'
import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import { normalizeConfig } from '../../../utils'
import Form from '@admin/components/form'
import * as styles from '../styles'
import PropTypes from 'prop-types'
import settings from '../settings'
import React from 'react'

const Display = (props) => <ResponsiveField { ...props } component={ FormDisplayField } />

class EditSignupFormBlock extends React.PureComponent {

  static propTypes = {
    device: PropTypes.string,
    entity: PropTypes.object,
    theme: PropTypes.object,
    type: PropTypes.string,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    const { entity } = props
    this.state = {
      default: entity,
      config: entity
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getForm() {
    const { device, entity, theme, type, website } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      onSuccess: this._handleDone,
      showHeader: false,
      buttons: [
        {
          label: 't(Cancel)',
          color: 'blue',
          basic: true,
          handler: this._handleCancel
        },
        {
          label: 't(Done)',
          color: 'blue',
          handler: this._handleSubmit
        }
      ],
      tabs: [
        {
          label: 't(Content)',
          sections: [
            {
              fields: [
                {
                  label: 't(Service Details)',
                  type: 'segment',
                  fields: [
                    {
                      label: 't(Setup Fee)',
                      name: 'content.setup_fee',
                      type: 'moneyfield',
                      required: true,
                      value: config.content.setup_fee
                    },
                    {
                      label: 't(Monthly Price)',
                      name: 'content.monthly_price',
                      type: 'moneyfield',
                      required: true,
                      value: config.content.monthly_price
                    },
                    {
                      label: 't(Free Setup)',
                      name: 'content.free_setup',
                      type: 'checkbox',
                      defaultValue: false,
                      value: config.content.free_setup
                    },
                    {
                      label: 't(Trial Length)',
                      name: 'content.trial_length',
                      type: 'numberfield',
                      required: true,
                      value: config.content.trial_length
                    }
                  ]
                },
                {
                  label: 't(Header Message)',
                  name: 'content.header',
                  type: 'htmlfield',
                  toolbar: ['b', 'em', 'u', 'ol', 'ul', 'a'],
                  required: true,
                  defaultValue: config.content.header
                },
                {
                  label: 't(Button Text)',
                  name: 'content.button_text',
                  type: 'textfield',
                  required: true,
                  defaultValue: config.content.button_text
                },
                {
                  label: 't(Button Suffix)',
                  name: 'content.button_suffix',
                  type: 'htmlfield',
                  toolbar: ['b', 'em', 'u', 'ol', 'ul', 'a'],
                  required: true,
                  defaultValue: config.content.button_suffix
                },
                {
                  label: 't(Confirmation Message)',
                  name: 'content.confirmation',
                  type: 'htmlfield',
                  toolbar: ['b', 'em', 'u', 'ol', 'ul', 'a'],
                  required: true,
                  defaultValue: config.content.confirmation
                }
              ]
            }
          ]
        },
        {
          label: 't(Styles)',
          sections: [
            {
              label: 't(Form)',
              collapsing: true,
              collapsed: true,
              fields: [
                {
                  label: 't(Elements)',
                  name: 'styles.display',
                  device,
                  canHover: false,
                  type: Display,
                  theme,
                  defaultValue: {},
                  value: config.styles.display
                },
                {
                  label: 't(Labels)',
                  name: 'styles.label',
                  type: LabelStyleField,
                  theme,
                  value: config.styles.label
                },
                {
                  label: 't(Inputs)',
                  name: 'styles.input',
                  type: InputStyleField,
                  theme,
                  value: config.styles.input
                },
                {
                  label: 't(Button)',
                  name: 'styles.button',
                  type: ButtonStyleField,
                  theme,
                  value: config.styles.button
                }
              ]
            },
            styles.BackgroundSection(config, device, theme, website, type),
            styles.BoxShadowSection(config, device, theme, website, type),
            styles.BorderSection(config, device, theme, website, type),
            styles.SizingSection(config, device, theme, website, type, true, true),
            styles.SpacingSection(config, device, theme, website, type),
            styles.TransformSection(config, device, theme, website, type),
            styles.AnimationSection(config, device, theme, website, type),
            styles.FiltersSection(config, device, theme, website, type),
            styles.OverflowSection(config, device, theme, website, type),
            styles.VisibilitySection(config, device, theme, website, type)
          ]
        },
        {
          label: 't(Settings)',
          sections: settings(config, type, entity)
        }
      ]
    }
  }

  _getNormalized() {
    const { entity } = this.props
    const { config } = this.state
    return normalizeConfig({
      ...entity,
      meta: config.meta,
      settings: config.settings,
      styles: config.styles,
      content: config.content
    })
  }

  _handleCancel() {
    this.props.onDone(this.state.default)
  }

  _handleChange() {
    const config = this._getNormalized()
    this.props.onChange(config)
  }

  _handleDone() {
    const config = this._getNormalized()
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

}

export default EditSignupFormBlock
