import EmailCampaignToken from '@apps/truevail/admin/tokens/email_campaign'
import EntityChooser from '@admin/components/entity_chooser'
import PropTypes from 'prop-types'
import React from 'react'

class Campaigns extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  _handleDone = this._handleDone.bind(this)

  render() {
    return <EntityChooser { ...this._getEntityChooser() } />
  }

  _getEntityChooser() {
    return {
      endpoint: '/api/admin/truevail/agency/email_campaigns/truevail',
      entity: 'email campaign',
      multiple: true,
      format: (email_campaign) => (
        <EmailCampaignToken email_campaign={ email_campaign } show_provider={ false } show_tags={ true } show_voice={ true } />
      ),
      preview: (entity) => `truevail_email_campaigns/${entity.code}`,
      showHeader: false,
      onDone: this._handleDone
    }
  }

  _handleDone(email_campaigns) {
    this.props.onNext({ email_campaigns })
  }

}

export default Campaigns
