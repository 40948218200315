import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser'
import Container from '@admin/components/container'
import ModalPanel from '@admin/components/modal_panel'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class Article extends React.Component {

  static propTypes = {
    article: PropTypes.object,
    onArticle: PropTypes.func,
    onBack: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleTransform = this._handleTransform.bind(this)

  render() {
    const { article } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-help-article">
          <div className="maha-help-article-body">
            <h1>{ article.title }</h1>
            { ReactHtmlParser(article.body, { transform: this._handleTransform }) }
          </div>
          { article.related.length > 0 &&
            <div className="maha-help-article-footer">
              <h3>Related Articles</h3>
              <ul>
                { article.related.map((article, index) => (
                  <li key={`article_${index}`}>
                    <Button { ...this._getRelated(article) } />
                  </li>
                )) }
              </ul>
            </div>
          }
        </div>
      </ModalPanel>
    )
  }

  _getPanel() {
    return {
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack }
      ]
    }
  }

  _getHref(label, link) {
    return {
      label,
      className: 'link',
      link
    }
  }

  _getLink(label, path) {
    return {
      label,
      className: 'link',
      handler: this._handleLink.bind(this, path)
    }
  }

  _getRelated(article) {
    return {
      label: article.title,
      className: 'link',
      handler: this._handleRelated.bind(this, article.id)
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleLink(path) {
    const number = parseInt(path.split('/')[2].split('-')[0])
    this.props.onArticle(number)
  }

  _handleRelated(id) {
    this.props.onArticle(id)
  }

  _handleTransform(node, index) {
    const { attribs, children, name, type } = node
    if(type !== 'tag') return

    if(attribs?.['data-html-block'] !== undefined) {
      const iframe = children[0]?.children[0]?.children[0]
      const src = iframe?.attribs?.src
      if(!src) return
      return (
        <div className="maha-help-article-video" key={index}>
          <div className="maha-help-article-video-body">
            { convertNodeToElement(node, index, this._handleTransform) }
          </div>
          <div className="maha-help-article-video-footer">
            <Button { ...this._getHref('Open video in another tab', src) } />
          </div>
        </div>
      )
    }

    if(name === 'a') {
      const href = node?.attribs?.href
      if(!href) return convertNodeToElement(node, index, this._handleTransform)

      const url = URL.parse(href)
      const linkText = this._extractLinkText(children)

      if(url.protocol === 'mailto:') {
        return <a href={href}>{linkText}</a>
      }

      if(url.protocol?.slice(0,4) !== 'http') {
        return convertNodeToElement(node, index, this._handleTransform)
      }

      if(url.hostname === 'help.truevail.com') {
        if(attribs.class === 'headerlink') return
        return <Button { ...this._getLink(linkText, url.pathname) } />
      }

      return <Button { ...this._getHref(linkText, href) } />
    }

    return convertNodeToElement(node, index, this._handleTransform)
  }

  _extractLinkText(children) {
    return children.map(child => {
      if (typeof child === 'string') return child
      if (child.type === 'text') return child.data
      if (child.type === 'tag') return this._extractLinkText(child.children)
      return ''
    }).join('')
  }

}

const mapResources = (props, context) => ({
  article: `/api/admin/help/articles/${props.id}`
})

export default Container(mapResources)(Article)
