import EmailInteractionsToken from '@admin/tokens/email_interactions'
import ContactAvatar from '@apps/crm/admin/tokens/contact_avatar'
import EmailTypeToken from '@admin/tokens/email_type'
import Avatar from '@admin/components/avatar'
import Logo from '@admin/components/logo'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'


const actions = {
  clicked: 't(clicked)',
  delivered: 't(delivered)',
  opened: 't(opened)'
}

class EmailToken extends React.Component {

  static propTypes = {
    email: PropTypes.object,
    perspective: PropTypes.string,
    show_interactions: PropTypes.string,
    show_timestamp: PropTypes.string
  }

  render() {
    const { email, perspective, show_timestamp, show_interactions } = this.props
    return (
      <div className="email-token">
        <div className="email-token-avatar">
          { email.type === 'thread' && perspective === 'contact' && email.from.contact &&
            <ContactAvatar { ...email.from.contact } width="32" />
          }
          { email.type !== 'thread' && perspective === 'contact' && email.program &&
            <Logo team={ email.program } width="32" />
          }
          { perspective === 'queue' && email.contact &&
            <ContactAvatar { ...email.contact } width="32" />
          }
          { perspective === 'program' && email.contact &&
            <ContactAvatar { ...email.contact } width="32" />
          }
          { perspective === 'program' && !email.contact && email.user &&
            <Avatar user={ email.user } width="32" presence={ false } />
          }
          { email.type === 'thread' && perspective === 'user' && email.contact &&
            <ContactAvatar { ...email.contact } width="32" />
          }
          { email.type === 'automation' && perspective === 'user' && email.program &&
            <Logo team={ email.program } width="32" />
          }
          { email.type === 'automation' && perspective === 'program' && !email.user && !email.contact &&
            <Avatar icon="envelope" width="32" />
          }
          { email.type === 'system' && !email.contact && !email.program &&
            <Logo team={{ name: 'Maha', logo: '/images/maha.png' }} width="32" />
          }
        </div>
        <div className="email-token-details">
          <EmailTypeToken type={ email.type } />
          <div className="email-token-row">
            <div className="email-token-recipient">
              { email.type === 'automation' && perspective === 'program' && email.user &&
                <>{ email.user.full_name }</>
              }
              { email.type === 'automation' && perspective === 'program' && email.contact &&
                <>{ email.contact.display_name === 'Unknown' ? email.to[0].address : email.contact.display_name }</>
              }
              { email.type === 'automation' && perspective === 'program' && !email.user && !email.contact &&
                <>{ email.to[0].address }</>
              }
              { email.type === 'automation' && perspective !== 'program' && email.program &&
                <>{ email.program.title }</>
              }
              { email.type === 'campaign' && perspective === 'queue' &&
                <>{ email.to[0].rfc822 }</>
              }
              { email.type === 'campaign' && perspective === 'program' && email.contact &&
                <>{ email.contact.display_name === 'Unknown' ? email.to[0].address : email.contact.display_name }</>
              }
              { email.type === 'campaign' && perspective === 'contact' && email.program &&
                <>{ email.program.title }</>
              }
              { email.type === 'thread' && perspective === 'user' && email.contact &&
                <>{ email.contact.display_name === 'Unknown' ? email.to[0].address : email.contact.display_name }</>
              }
              { email.type === 'thread' && perspective === 'contact' && email.user &&
                <>{ email.from.name || email.from.address }</>
              }
              { email.type === 'thread' && perspective === 'program' && email.user &&
                <>{ [...email.to,...email.cc].map(email => email.name || email.address).join(', ') }</>
              }
              { email.type === 'system' && !email.contact && !email.program &&
                <>Maha</>
              }
              { email.type === 'system' && perspective === 'program' && email.contact &&
                <>{ email.contact.display_name === 'Unknown' ? email.to[0].address : email.contact.display_name }</>
              }
              { email.type === 'system' && perspective !== 'program' && email.program &&
                <>{ email.program.title }</>
              }
            </div>
            { show_timestamp !== false &&
              <div className="email-token-timestamp">
                { email.sent_at ?
                  <>{ this._getTimestamp() }</> :
                  <T text="t(SENDING)" />
                }
              </div>
            }
          </div>
          <div className="email-token-row">
            <div className="email-token-subject">
              { email.subject }
            </div>
            { email.thread &&
              <div className="email-token-count">
                { email.thread.emails_count }
              </div>
            }
          </div>
          { show_interactions !== false &&
            <EmailInteractionsToken email={ email } />
          }
        </div>
      </div>
    )
  }

  _getTimestamp() {
    const { email } = this.props
    const timestamp = moment(email.sent_at)
    const today = moment()
    const yesterday = moment().subtract(1, 'day')
    if(timestamp.isSame(today, 'day')) return timestamp.format('h:mm A')
    if(timestamp.isSame(yesterday, 'day')) return 'Yesterday'
    return timestamp.format('MM/DD/YY')
  }

}

export default EmailToken
