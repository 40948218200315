import Panel from '@admin/components/panel'
import moment from 'moment'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Sent)',
  collection: {
    endpoint: '/api/admin/truevail/admin/processings',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Day)', key: 'processed_at', primary: true, format: (record) => (
        <div className="token">
          { moment(record.processed_at).tz(context.admin.account.timezone).format('YYYY-MM-DD') }
        </div>
      ) },
      { label: 't(Sent)', key: 'emails_count', collapsing: true, align: 'right' }
    ],
    defaultQuery: { 
      interval: 'all',
      domain_segment_id: props.domain_segment.id
    },
    defaultSort: { key: 'processed_at', order: 'desc' },
    empty: {
      icon: 'envelope',
      title: 't(No Emails)',
      text: 't(There are no sent emails for this domain segment)'
    },
    entity: 'day',
    onClick: (record) => context.router.push(`/admin/truevail/admin/sent/domain_segments/${props.domain_segment.id}/day/${moment(record.processed_at).format('YYYY-MM-DD')}`)
  }
})

export default Panel(null, mapPropsToPanel)
