import ModalPanel from '@admin/components/modal_panel'
import IFrame from '@admin/components/html/iframe'
import Message from '@admin/components/message'
import Search from '@admin/components/search'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import Results from './results'
import React from 'react'

class EntityChooser extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    endpoint: PropTypes.string,
    entity: PropTypes.string,
    format: PropTypes.func,
    multiple: PropTypes.bool,
    preview: PropTypes.func,
    showHeader: PropTypes.bool,
    title: PropTypes.string,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onDone: PropTypes.func
  }

  static defaultProps = {
    multiple: false,
    onDone: () => {}
  }

  state = {
    chosen: [],
    previewing: null
  }

  _handleChoose = this._handleChoose.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handlePreview = this._handlePreview.bind(this)

  render() {
    const { chosen, previewing } = this.state
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-entity-chooser">
          <div className="maha-entity-chooser-list">
            <Search { ...this._getSearch() } />
          </div>
          <div className="maha-entity-chooser-preview">
            <div className="maha-entity-chooser-preview-body">
              { previewing ?
                <IFrame { ...this._getIFrame() } /> :
                <Message { ...this._getMessage() } />
              }
            </div>
            { chosen.length > 0 &&
              <div className="maha-entity-chooser-preview-footer">
                <Button { ...this._getDone() } />                
              </div>
            }
          </div>
        </div>
      </ModalPanel>
    )    
  }

  _getDone() {
    const { entity, multiple } = this.props
    const { chosen } = this.state
    return {
      label: `t(Choose) ${multiple ? pluralize(_.startCase(entity), chosen.length, true) : _.startCase(entity)}`,
      color: 'blue',
      handler: this._handleDone
    }
  }

  _getIFrame() {
    const { previewing } = this.state
    const { preview } = this.props
    return {
      src: `/mjson/dynamic/${preview(previewing)}?mode=show`
    }
  }

  _getMessage() {
    const { entity } = this.props
    return {
      icon: 'file-o',
      title: `t(Choose) ${_.startCase(entity)}`,
      text: 't(Choose an item to see a preview)'
    }
  }

  _getPanel() {
    const { entity, showHeader, title, onBack, onCancel } = this.props
    return {
      title: title || `t(Choose) ${_.startCase(entity)}`,
      showHeader,
      leftItems: [
        ...onBack ? [
          { icon: 'chevron-left', handler: onBack }
        ] : [
          { label: 't(Cancel)', handler: onCancel }
        ]
      ]
    }
  }

  _getSearch() {
    const { defaultValue, endpoint, entity, format, multiple } = this.props
    const { chosen, previewing } = this.state
    return {
      defaultValue,
      endpoint,
      entity,
      layout: Results,
      props: {
        chosen,
        format, 
        multiple,
        previewing,
        onChooseRecord: this._handleChoose,
        onPreview: this._handlePreview
      }
    }
  }

  _handleChoose(record) {
    const { multiple } = this.props
    const { chosen } = this.state
    this.setState({
      chosen: multiple ? _.xorBy(chosen, [record], (record) => record.id) : [record]
    })
  }

  _handleDone() {
    const { multiple } = this.props
    const { chosen } = this.state
    this.props.onDone(multiple ? chosen : chosen?.[0])
  }

  _handlePreview(previewing) {
    this.setState({ previewing })
  }

}

export default EntityChooser
