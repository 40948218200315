import Panel from '@admin/components/panel'
import moment from 'moment-timezone'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Sent)',
  collection: {
    endpoint: '/api/admin/truevail/admin/processings',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Minute)', key: 'processed_at', primary: true, format: (record) => (
        <div className="token">
          { moment(record.processed_at).tz(context.admin.account.timezone).format('YYYY-MM-DD [@] hh:mmA z') }
        </div>
      ) },
      { label: 't(Sent)', key: 'emails_count', collapsing: true, align: 'right' }
    ],
    defaultQuery: { 
      ...props.params.domain_id ? {
        domain_id: props.params.domain_id
      } : props.params.domain_segment_id ? {
        domain_segment_id: props.params.domain_segment_id
      } : props.params.segment_id ? {
        segment_id: props.params.segment_id
      } : {},
      interval: 'minute',
      value: props.params.value
    },
    defaultSort: { key: 'processed_at', order: 'desc' },
    empty: {
      icon: 'envelope',
      title: 't(No Emails)',
      text: 't(There are no sent emails)'
    },
    entity: 'minute'
  }
})

export default Panel(null, mapPropsToPanel)
