import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Testimonials extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true,
          handler: this._handleBack
        },
        { 
          label: 't(Next)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            {
              label: 't(Testimoinals)',
              type: 'segment',
              fields: [
                { 
                  name: 'website_testimonials',
                  type: 'collectionfield', 
                  fields: [
                    { 
                      label: 't(Client Name)', 
                      name: 'client_name', 
                      type: 'textfield' 
                    },
                    { 
                      label: 't(Text)', 
                      name: 'text', 
                      placeholder: 't(4 sentences max recommended)', 
                      type: 'textarea' 
                    },
                    { 
                      label: 't(Date)', 
                      name: 'date', 
                      placeholder: 't(Month and Year)', 
                      type: 'textfield' 
                    },
                    { 
                      label: 't(Trip Short Description)', 
                      name: 'description', 
                      placeholder: 't(Example: 3-Week Caribbean Cruise)', 
                      type: 'textfield' 
                    }
                  ], 
                  entity: 'testimonial', 
                  orderable: false, 
                  cloneable: false, 
                  token: ({ value }) => (
                    <div className="token">{value.client_name}</div>
                  ), 
                  defaultValue: config.website_testimonials || [] }
              ]
            }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata } = this.props
    this.props.onNext({
      ...formdata,
      ...config
    })
  }

}

export default Testimonials
