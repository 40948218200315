import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Subdomains)',
  collection: {
    endpoint: `/api/admin/truevail/admin/domains/${props.domain.id}/subdomains`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'name', primary: true }
    ],
    defaultSort: { key: 'name', order: 'asc' },
    empty: {
      icon: 'globe',
      title: 't(No Subdomains)',
      text: 't(There are no subdomains)'
    },
    entity: 'subdomain',
    onClick: (record) => context.router.push(`/admin/truevail/admin/domains/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
