import SocialCampaignToken from '@apps/truevail/admin/tokens/social_campaign'
import EntityChooser from '@admin/components/entity_chooser'
import PropTypes from 'prop-types'
import React from 'react'

class Campaigns extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  _handleDone = this._handleDone.bind(this)

  render() {
    return <EntityChooser { ...this._getEntityChooser() } />
  }

  _getEntityChooser() {
    return {
      endpoint: '/api/admin/truevail/agency/social_campaigns/truevail',
      entity: 'social campaign',
      format: (social_campaign) => (
        <SocialCampaignToken social_campaign={ social_campaign } show_provider={ false } show_tags={ true } show_voice={ true }  />
      ),
      multiple: true,
      preview: (entity) => `truevail_social_campaign_variants/${entity.variants[0].code}`,
      showHeader: false,
      onDone: this._handleDone
    }
  }

  _handleDone(social_campaigns) {
    this.props.onNext({ social_campaigns })
  }

}

export default Campaigns
