import Container from '@admin/components/container'
import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Complete from './complete'
import Overview from './overview'
import React from 'react'

class Configure extends React.PureComponent {

  static contextTypes = {
    flash: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.any,
    completed: PropTypes.any,
    endpoint: PropTypes.string,
    intro: PropTypes.any,
    mode: PropTypes.string,
    requirements: PropTypes.array,
    setup: PropTypes.object,
    title: PropTypes.string
  }

  stackRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleClose = this._handleClose.bind(this)
  _handleComplete = this._handleComplete.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)
  _handleSave = this._handleSave.bind(this)
  _handleTask = this._handleTask.bind(this)

  constructor(props) {
    super(props)
    const { configuration, status } = props.setup
    this.state = {
      configuration,
      status: status === 'configured' ? 'complete' : 'overview'
    }
  }

  render() {
    return <Stack { ...this._getStack() } />
  }

  componentDidUpdate(prevProps, prevState) {
    const { configuration } = this.state
    if(!_.isEqual(configuration, prevState.configuration) && prevState.configuration !== null) {
      this._handleSave()
    }
  }

  _getCompleted() {
    const { completed, title } = this.props
    return { 
      completed,
      title,
      onBack: this._handlePop,
      onClose: this._handleClose
    }
  }

  _getOverview() {
    const { intro, mode, requirements, title } = this.props
    const { configuration } = this.state
    return {
      configuration,
      intro,
      mode,
      requirements,
      title,
      onCancel: this._handleCancel,
      onDone: this._handleComplete,
      onSave: this._handleSave,
      onTask: this._handleTask
    }
  }

  _getStack() {
    const { setup } = this.props
    return {
      sldieFirst: false,
      display_name: 'configure',
      ref: this.stackRef,
      initial: [
        { component: Overview, props: this._getOverview.bind(this) },
        ...setup.status === 'configured' ? [
          { component: Complete, props: this._getCompleted.bind(this) }
        ] : []
      ]
    }
  }

  _getTask(task) {
    const { configuration } = this.state
    const { advisor } = this.props
    return {
      advisor,
      configuration,
      onDone: this._handleDone.bind(this, task),
      onNext: this._handleNext.bind(this, task),
      onBack: this._handlePop
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleClose() {
    this.context.modal.close()
  }

  _handleComplete() {
    const { endpoint, setup } = this.props
    this.context.network.request({
      endpoint: `${endpoint}/complete`,
      method: 'PATCH',
      onSuccess: () => {
        this.setState({
          status: 'complete'
        }, this._handleCompleted)
      },
      onFailure: (e) => this.context.flash.set('error', 't(Unable to complete configuration)')
    })
  }

  _handleCompleted() {
    this._handlePush(Complete, this._getCompleted.bind(this))
  }

  _handleDone(task, configuration) {
    this.setState({
      configuration: {
        ...this.state.configuration,
        ...configuration,
        [`${task.code}_status`]: 'completed'
      }
    })
    this._handlePop()
  }

  _handleNext(task, configuration) {
    this.setState({
      configuration: {
        ...this.state.configuration,
        ...configuration,
        [`${task.code}_status`]: _.isEqual(this.state.configuration, configuration) ? 'completed' : null
      }
    })
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

  _handleSave() {
    const { configuration } = this.state
    const { endpoint } = this.props
    this.context.network.request({
      endpoint,
      method: 'PATCH',
      body: { configuration },
      onFailure: (e) => this.context.flash.set('error', 't(Unable to save configuration)')
    })
  }

  _handleTask(task) {
    this._handlePush(task.modal, this._getTask.bind(this, task))
  }

}

const mapResources = (props) => ({
  setup: `${props.endpoint}/edit`
})

export default Container(mapResources)(Configure)
