import ProfileSetupField from '@apps/truevail/admin/components/profilesetupfield'
import Button from '@admin/components/button'
import Form from '@admin/components/form'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Profiles extends React.PureComponent {

  static propTypes = {
    advisor: PropTypes.object,
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { advisor } = this.props.props
    const { program } = advisor.team
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true, handler: this._handleBack 
        },
        { 
          label: 't(Next)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Social Profiles)',
              instructions: (
                <>
                  <p><T text="t(Add the Facebook Page(s) and/or Instagram Business Profile(s) you would like for us to post your content to)" /></p>
                  <p><T text="t(We cannot schedule posts to Personal Facebook or Personal Instagram accounts. You must have a Facebook Page and/or Instagram Business profile for us to schedule your posts. Additionally, the accounts must be linked for us to post to your Instagram Business Profile)" /></p>
                  <ul>
                    <li><Button text="t(How to create a Facebook Page)" className="link" link="https://www.facebook.com/business/help/473994396650734?id=939256796236247" /></li>
                    <li><Button text="t(How to create an Instagram Business Profile)" className="link" link="https://help.instagram.com/502981923235522" /></li>
                    <li><Button text="t(How to link your Instagram Business Profile to your Facebook Page)" className="link" link="https://help.instagram.com/176235449218188" /></li>
                  </ul>
                </>
              ),
              name: 'social_profiles',
              type: ProfileSetupField,
              program,
              defaultValue: config.social_profiles,
              required: true 
            }
          ] 
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata } = this.props
    this.props.onNext({
      ...formdata,
      ...config
    })
  }

}

export default Profiles
