import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Sent)',
  collection: {
    endpoint: '/api/admin/truevail/admin/processings',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Segment)', key: 'segment.text', sort: 'segment', primary: true },
      { label: 't(Sent)', key: 'emails_count', collapsing: true, align: 'right' }
    ],
    defaultQuery: { 
      ...props.params.domain_id ? { 
        domain_id: props.params.domain_id
      } : {},
      interval: props.params.interval === 'day' ? 'hour' : 'minute',
      group_by: 'segment',
      value: props.params.value
    },
    defaultSort: { key: 'segment', order: 'asc' },
    empty: {
      icon: 'envelope',
      title: 't(No Emails)',
      text: 't(There are no sent emails)'
    },
    entity: 'segments',
    onClick: (record) => {
      const parts = ['/admin/truevail/admin/sent']
      if(props.params.domain_id) parts.push(`domain_segments/${record.domain_segment.id}`)
      else parts.push(`segments/${record.segment.id}`)
      if(props.params.interval === 'day') parts.push(`day/${props.params.value}`)
      if(props.params.interval === 'hour') parts.push(`hour/${props.params.value}`)
      context.router.push(parts.join('/'))
    }  
  }
})

export default Panel(null, mapPropsToPanel)
