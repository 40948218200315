import EntityChooser from '@admin/components/entity_chooser'
import ContentToken from '@apps/maha/admin/tokens/content'
import { getCode } from '@core/utils/codes'
import PropTypes from 'prop-types'
import React from 'react'

class NewModule extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    flash: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    theme: PropTypes.object,
    type: PropTypes.string,
    onDone: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleFetch = this._handleFetch.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    return <EntityChooser { ...this._getChooser() } />
  }

  _getChooser() {
    const { admin } = this.context
    return {
      endpoint: '/api/admin/content/email_modules/library',
      entity: 'module',
      format: (content) => (
        <ContentToken content={ content } show_provider={ true } show_tags={ false } show_voice={ admin.agency !== null } />
      ),
      multiple: false,
      preview: (entity) => `maha_contents/${entity.code}`,
      onCancel: this._handleCancel,
      onDone: this._handleFetch
    }
  }

  _getUnique(config) {
    return {
      ...config,
      id: getCode(10),
      content: {
        ...config.content,
        ...config.content.children ? {
          children: config.content.children.map(child => {
            return this._getUnique(child)
          })
        } : {}
      }
    } 
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleFetch(email_article) {
    this.context.network.request({
      endpoint: `/api/admin/content/email_articles/library/${email_article.id}`,
      method: 'GET',
      onFailure: () => this.context.flash.set('error', 't(Unable to load email article)'),
      onSuccess: ({ data }) => {
        this._handleSubmit(data)
      }
    })
  }

  _handleSubmit(email_article) {
    this.props.onDone({
      ...this._getUnique(email_article.config.entity.content.children[0]),
      meta: {
        label: 'Email Article'
      }
    })
    this.context.modal.close()
  }

}

export default NewModule
